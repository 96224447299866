<template>
  <div>
    <div style="font-size: 36px;">{{ $t('param.a1') }}</div>
    <!-- search -->
    <div v-if="quanxian == 'Admin' || quanxian == 'Config'" class="flex" style=" margin: 30px 0;">
      <div style="width: 94%;" class="input-search">
        <div class="el-date-input" style="z-index: 100;opacity:0;">
          <el-date-picker
              style="width: 100px;"
              @change="getList"
              v-model="date"
              type="daterange"
              range-separator="-"
              :start-placeholder="$t('common.a10')"
              :end-placeholder="$t('common.a11')">
          </el-date-picker>
        </div>
        <div class="el-date-input"
             style="height: 40px;line-height: 40px;color: #9FA3AF;display: flex;font-size: 21px;background-color: #fff;">
          <div style="width: 1px;height: 27px;margin: 7px 10px 0 0;background: #9FA3AF;margin-right: 10px;"></div>
          <div style="margin-right: 10px">{{ $t('param.a9') }}<i class="el-icon-caret-bottom"></i></div>

        </div>
        <input v-model="filename" type="text" class="search" :placeholder="$t('param.a7')">
      </div>
      <div class="search-btn" @click="getList"><i class="el-icon-search"></i></div>
    </div>
    <div v-else style="margin-top: 30px;"></div>
    <!-- button -->
    <div class="tool-btn flex">
      <div>
        <el-button v-if="quanxian == 'Admin' || quanxian == 'Config'" size="large" type="primary"
                   style="margin-right: 30px;" @click="pushCreate">{{ $t('common.a1') }}
        </el-button>
        <el-button v-if="quanxian == 'Admin'" size="large" @click="deleteAll">{{ $t('common.a2') }}</el-button>
      </div>
      <el-tag
          closable
          type="info"
          effect="plain"
          size="medium"
          @close="closeDate"
          @click="closeDate"
          v-if="date.length"
      >
        {{ dateToString }}
      </el-tag>
      <!-- <el-button size="large" type="primary">导入</el-button> -->
    </div>

    <el-table
        :data="tableData"
        class="table"
        border
        @selection-change="checkSelect"
        @row-click="pushDetail"
        height="450"
        style="width: 100%;">
      <el-table-column
          type="selection"
          width="60">
      </el-table-column>
      <el-table-column
          type="index"
          :label="$t('param.number')"
          width="180">
      </el-table-column>
      <el-table-column
          prop="name" sortable
          :label="$t('param.a8')">
      </el-table-column>
      <el-table-column
          prop="version"
          :label="$t('param.a13')">
      </el-table-column>
      <el-table-column
          prop="date" sortable
          width="180"
          :formatter="_formatDate"
          :label="$t('param.a9')">
      </el-table-column>
      <!-- <el-table-column
        prop="address"
        width="180"
        label="user">
      </el-table-column> -->
      <el-table-column
          prop="address"
          width="180"
          :label="$t('param.a10')">
        <template slot-scope="scoped">
          <el-button type="primary" v-if="scoped.row.complete == 1" @click.stop="exportFile(scoped.row)">
            {{ $t('param.a101') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center;">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          background
          layout="prev, pager, next"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/http';
export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      total: 0,
      filename: '',
      date: [],
      dateToString: '',
      deleteList: [],
      tableData: [],
      quanxian: ''
    }
  },
  created() {
    this.getList();
    sessionStorage.removeItem('file')
    sessionStorage.removeItem('edit')
    sessionStorage.removeItem('menuCache')
    sessionStorage.removeItem('shortcutkeysCache')
    this.$root.status = 'PhoneProfile'

    let user = sessionStorage.getItem('user');
    if (user) this.quanxian = JSON.parse(user).quanxian;
  },

  methods: {
    exportFile(row) {
      row.url.forEach(v => {
        if (v.indexOf('doc') > -1) {
          this.downloadByLink(v, '_blank')
        } else {
          let name = v.substr(v.lastIndexOf('/') + 1)
          this.downloadByURL(v, name || '', name ? name.split('.')[1] : '')
        }
      })
    },
    downloadByLink(url, target) {
      const link = document.createElement('a');
      // <a href="https://url.docx" target="undefined" download="fileName.docx"></a>

      link.href = url;
      link.target = target;

      link.click();
      // if(document.createEvent) {
      //     const e = document.createElement('MouseEvents');
      //     e.initEvent('click', true, true);
      //     link.dispatchEvent(link);
      //     return true;
      // }

    },
    downloadByURL(url, name, type) {

      // if( !fileName?.includes('.')) {
      //     const index = url.lastIndexOf('.');
      //     fileName = `${fileName}.${url.substr(index + 1)}`;
      // }

      this.getRequest(url, {}, (res) => {
        // let blob
        // if(type) {
        //   blob = new Blob([res], {type});
        // } else {
        //    blob = new Blob([downResult], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
        // }
        const a = document.createElement('a');
        const href = window.URL.createObjectURL(res);
        a.href = href;
        a.download = name || '未命名';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(href);
        document.body.removeChild(a);
      })
      // const xhr = new XMLHttpRequest();
      // xhr.open('GET', url, true);
      // xhr.responseType = 'blob';
      // xhr.onload = (e) => {
      //     // const url = window.URL.createObjectURL(xhr.response);
      //     // 调用上面封装的函数
      //     // this.downloadByLink(url,target);
      //     let blob = new Blob([xhr.response], {type: fileType});
      //     const a = document.createElement('a');
      //     const href = window.URL.createObjectURL(blob);
      //     a.href =  href;
      //     a.download = fileName;
      //     document.body.appendChild(a);
      //     a.click();
      //     window.URL.revokeObjectURL(href);
      //     document.body.removeChild(a);
      // };
      // xhr.onerror = () => {};
      // xhr.send();
    },
    getList() {
      let params = {
        page: this.pageNum,
        page_size: this.pageSize,
        uid: sessionStorage.getItem('id'),
      }
      if (this.filename) {
        params.filename = this.filename;
      }
      if (this.date.length) {
        params.stime = Number((this.date[0].getTime() / 1000).toFixed(0));
        params.etime = Number(((this.date[1].getTime() + 86399999) / 1000).toFixed(0));

        this.dateToString = new Date(this.date[0].getTime() + 3600000 * 8).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '').substr(0, 10) + " ~ " +
            new Date(this.date[1].getTime() + 3600000 * 8).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '').substr(0, 10)
      }
      this.postRequest('index/get_phone_profile_list', params, res => {
        this.tableData = res.data.info;
        this.total = res.data.count;
      }, true)
    },
    pushDetail(e) {
      sessionStorage.setItem('file', JSON.stringify(e));
      sessionStorage.setItem('edit', 'true');
      this.$root.status = 0
      this.$router.push('/BasicParameters')
    },
    checkSelect(e) {
      this.deleteList = e.map(v => v.id)
    },
    pushCreate() {
      this.$router.push('/BasicParameters')
      this.$root.status = 'BasicParameters';
      sessionStorage.setItem('edit', 'false');
    },
    deleteAll() {
      if (this.deleteList.length == 0) return;
      // this.$confirm('是否删除选中的'+ this.deleteList.length+'条数据', this.$t('common.a2'), {
      this.$confirm(this.$t('common.a22'), this.$t('message.a10'), {
        // this.$confirm('', this.$t('common.a2'), {
        confirmButtonText: this.$t('cp.a4'),
        cancelButtonText: this.$t('cp.a5'),
        type: 'warning'
      }).then(() => {
        this.postRequest('index/del_phone_profile', {ids: this.deleteList.join(',')}, () => {
          this.$message({
            type: 'success',
            message: this.$t('message.a6')
          });
          this.getList();
        })
      })
    },

    closeDate() {
      this.date = [];
      this.dateToString = '';
      this.getList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    _formatDate(row, col, value, index) {
      return formatDate(value);
    },
  }
}
</script>

<style>
.input-search > .el-date-input {

}
</style>
<style scoped>
.search {
  width: 100%;
  height: 46px;
  border: 3px solid #5D80F5;
  border-radius: 1px;
  font-size: 18px;
  text-indent: 20px;
  box-sizing: border-box;

}

.search:focus-visible {
  outline: none;
}

.input-search > .el-date-input {
  position: absolute;
  right: 0;
  top: 3px;
}

.input-search {
  position: relative;
  width: 94%;
}

.search-btn {
  width: 6%;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
  color: #C3D0FB;
  background: #5D80F5;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.tool-btn {
}

.table {
  margin: 30px 0;
}
</style>
