<template>
  <div class="hello">
    <head-menu></head-menu>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import headMenu from '../assets/commonComp/header'
export default {
  components: {
    headMenu
  },
  props: {
    msg: String                     
  }
}
</script>

<style>
.search .el-input__inner {
  border-radius: 10px;
  background: #9DB2F9;
  border: none;
  color: white;
}

</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  width: 100%;
  height: 100vh;
  overflow: auto;
  background: #F5F7FA;
}
.hearder-nav-wrap {
  width: 100%;
  height: 70px;
  background: #5D80F5;
}
.hearder-nav {
  width: 70%;
  line-height: 70px;
  min-width: 1200px;
  margin: 0 auto;
  color: white;
  display: flex;
  justify-content: space-between;
}

.header-right-tools {
  display: flex;
  justify-content: space-between;
  font-size: .35rem;
}
.header-right-tools li {
  margin-right: 20px;
}
.search {
  width: 190px;
}
.shu {
  width: 1px;
  height: 35px;
  background: white;
  margin-top: 17px;
}
.change-pw {
  padding: 0 10px;
  height: 30px;
  font-size: .3rem;
  margin-top: 20px;
  vertical-align: middle;
  line-height: 30px;
  border: 1px solid white;
  border-radius: 30px;
  cursor: pointer;
  text-align: center;
}

.content {
  width: 70%;
  min-width: 1200px;
  background: white;
  margin: 35px auto 0;
}
</style>
