import Vue from 'vue'
import Router from 'vue-router'

import Login from "@/components/Login";
import Layout from "@/components/Layout";
import NavMenus from "@/components/parameters/NavMenus";
import PhoneProfile from "@/components/parameters/PhoneProfile";
const BasicParameters = () => import('@/components/parameters/BasicParameters.vue');
const Contacts = () => import('@/components/parameters/Contacts.vue');
const Menus = () => import('@/components/parameters/Menus.vue');
const Shortcutkeys = () => import('@/components/parameters/Shortcutkeys.vue');
const ForbiddenNumbers = () => import('@/components/parameters/ForbiddenNumbers.vue');
import NavMenus2 from "@/components/tools/NavMenus";
import Users from "@/components/tools/Users";
const UserProfiles = () => import('@/components/tools/UserProfiles.vue');
const PhoneIMEI = () => import('@/components/tools/PhoneIMEI.vue');
const ImportLogsFile = () => import('@/components/tools/ImportLogsFile.vue');
const ReadLogsFile = () => import('@/components/tools/ReadLogsFile.vue');
const ImportNewVersion = () => import('@/components/tools/ImportNewVersion.vue');

const ResetPassword = () => import('@/components/ResetPassword.vue');
Vue.use(Router)

const router = new Router({
  mode: 'hash',
  routes: [
    // 登录页面路由,一级路由
    {
      path: "/",
      name: "login",
      component: Login,
      meta: {
        title: "登录页",
        noAuth: true
      }
    },
    {
      path: "/ResetPassword",
      name: "ResetPassword",
      component: ResetPassword,
      meta: {
        title: "修改密码",
        noAuth: true
      }
    },
    {
      path: '/home',
      name: 'home',
      component: Layout,
      redirect: '/NavMenus',
      children: [
        {
          path: '/NavMenus',
          name: 'NavMenus',
          component: NavMenus,
          redirect: '/PhoneProfile',
          beforeEnter: (to, from, next) => {
            sessionStorage.setItem('location', 'parameters');
            next();
          },
          children: [
            {
              path: '/PhoneProfile',
              name: 'PhoneProfile',
              component: PhoneProfile,
            },
            {
              path: '/BasicParameters',
              name: 'BasicParameters',
              component: BasicParameters,
            },
            {
              path: '/Contacts',
              name: 'Contacts',
              component: Contacts,
            },
            {
              path: '/Menus',
              name: 'Menus',
              component: Menus,
            },
            {
              path: '/Shortcutkeys',
              name: 'Shortcutkeys',
              component: Shortcutkeys,
            },
            {
              path: '/ForbiddenNumbers',
              name: 'ForbiddenNumbers',
              component: ForbiddenNumbers,
            },
          ]
        },
        {
          path: '/NavMenus2',
          name: 'NavMenus2',
          component: NavMenus2,
          redirect: '/Users',
          beforeEnter: (to, from, next) => {
            sessionStorage.setItem('location', 'tools');
            next();
          },
          children: [
            {
              path: '/Users',
              name: 'Users',
              component: Users,
              beforeEnter: (to, from, next) => {
                let quanxian = sessionStorage.getItem('user');
                quanxian = JSON.parse(quanxian).quanxian;
                if(quanxian == 'Admin') {
                  next();
                } else {
                  router.push('/UserProfiles')
                }
              }
            },
            {
              path: '/UserProfiles',
              name: 'UserProfiles',
              component: UserProfiles,
            },
            {
              path: '/PhoneIMEI',
              name: 'PhoneIMEI',
              component: PhoneIMEI,
            },
            {
              path: '/ImportLogsFile',
              name: 'ImportLogsFile',
              component: ImportLogsFile,
            },
            {
              path: '/ReadLogsFile',
              name: 'ReadLogsFile',
              component: ReadLogsFile,
            },
            {
              path: '/ImportNewVersion',
              name: 'ImportNewVersion',
              component: ImportNewVersion,
            },
          ]
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  const uid = sessionStorage.getItem('id');
  let quanxian = sessionStorage.getItem('user')
  if(quanxian) {
    quanxian = JSON.parse(quanxian);
  }
  if (to.path == '/') {
      next();
  } else {
      if(uid && uid != 'undefined') {
        if(quanxian == 'Mainteneur') {
          
        }
        return next();
      } else {
        Vue.logoutAndClear({type: 0})
          next({
              path: "/"
          });
      }
  }
})

export default router;