<template>
  <div>
    <div v-show="edit == 'false'" class="title-profile">{{$t('common.a1')}} Profile...</div>
    <div v-show="edit == 'true'" class="title-profile">{{$t('common.a3')}} Profile：{{filename}}</div>
    <div class="content">
      <el-menu
        class="nav"
        @select="navChange"
        background-color="#fff"
        router
        :default-active="navIndex">
        <el-menu-item index="PhoneProfile" route>
          <span slot="title">{{$t('param.a1')}}</span>
        </el-menu-item>
        <el-menu-item index="BasicParameters" :disabled="navIndex != 'BasicParameters' && $root.status != 0">
          <span slot="title">{{$t('param.a2')}}</span>
        </el-menu-item>
        <el-menu-item index="Contacts" :disabled="navIndex != 'Contacts' && $root.status != 0">
          <span slot="title">{{$t('param.a3')}}</span>
        </el-menu-item>
        <el-menu-item index="Menus" :disabled="navIndex != 'Menus' && $root.status != 0">
          <!-- <i class="el-icon-setting"></i> -->
          <span slot="title">{{$t('param.a4')}}</span>
        </el-menu-item>
        <el-menu-item index="Shortcutkeys" :disabled="navIndex != 'Shortcutkeys' && $root.status != 0">
          <span slot="title">{{$t('param.a5')}}</span>
        </el-menu-item>
        <el-menu-item index="ForbiddenNumbers" :disabled="navIndex != 'ForbiddenNumbers' && $root.status != 0">
          <span slot="title">{{$t('param.a6')}}</span>
        </el-menu-item>

      </el-menu>
      <div style="width: 2px;height: 100%;background: #E6EAF0;"></div>
      <div class="content-item">
        <el-scrollbar style="height: 100%;">
          <router-view style="padding: 45px 45px 0 45px;"></router-view>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      navIndex: 'PhoneProfile',
      edit: '',
      filename: ''
    }
  },
  watch: {
    '$root.status':{
      handler(){
        if(this.$root.status != 0) {
          this.navIndex = this.$root.status;
        } else {
          this.navIndex = this.$route.name
        }

      },
      deep: true
    },
    $route: {
      handler: function(val, oldVal){
        this.edit = sessionStorage.getItem('edit');
        if(val.path == '/PhoneProfile') {
          this.filename = ''
          this.edit = ''
        } else if(this.edit == 'true') {
          this.filename = JSON.parse(sessionStorage.getItem('file')).filename
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    navChange(e,v) {
      if(this.$root.status != 0) return ;
      this.navIndex = e;
    }
  }
}
</script>

<style>
.content-item .el-scrollbar__wrap {
 overflow-x: hidden;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title-profile {
  position: absolute;
  top: 80px;
  font-size: 16px;
}
.content {
  display: flex;
  height: 85vh;
}
.nav {
  width: 20%;
  min-width: 265px;
}
.content-item {
  width: 80%;
  height: 100%;
  box-sizing: border-box;
  
  box-sizing: border-box;
}
</style>
