<template>
  <div>
    <div class="hearder-nav-wrap">
      <div class="user-info">
        <span class="about" @click="about">{{ $t('header.a7') }}</span>
        <span style="font-size: 18px;color: white;">{{ userInfo.name }} </span>
        <span style="font-size: 12px;color: #dddddd;">({{ userInfo.quanxian }})</span>
      </div>
      <div class="hearder-nav">
        <div>Logo</div>
        <ul class="header-right-tools">
          <li :style="location != 'parameters'? 'color: #DDDDDD;' : ''" @click="goParameters" style="cursor: pointer">
            {{ $t('header.a2') }}
          </li>
          <li :style="location != 'tools'? 'color: #DDDDDD;' : ''" style="cursor: pointer" @click="goTools">
            {{ $t('header.a3') }}
          </li>
          <!-- <li>
            <el-input size="small" class="search" prefix-icon="el-icon-search" :placeholder="$t('header.a4')"></el-input>
          </li> -->
          <li class="shu"></li>
          <li class="change-pw" @click="goCP">{{ $t('header.a5') }}</li>
          <li class="change-pw" @click="logout">{{ $t('header.a6') }}</li>
          <li @click="checkLanguage('fr')" style="margin-top: 13px;cursor: pointer;"><img src="../../assets/img/fr.png"
                                                                                          alt=""></li>
          <li @click="checkLanguage('en')" style="margin-top: 13px;cursor: pointer;"><img src="../../assets/img/en.png"
                                                                                          alt=""></li>
          <!-- <li @click="checkLanguage('fa')">fa</li> -->
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    msg: String
  },
  data() {
    return {
      location: 'parameters',
      userInfo: {}
    }
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.location = sessionStorage.getItem('location')
      },
      // 深度观察监听
      deep: true
    }
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem('user'))
  },
  methods: {
    about() {
      let version = sessionStorage.getItem('version')
      
      if (typeof version == "undefined" || null == version || "" === version){
        version = process.env.VUE_APP_VERSION
        this.postRequest('index/get_version', null, 
          res => {
            console.log('res='+res.code)
            if (res.code == 200) {
              if (typeof res.data.version !== "undefined" && null !== res.data.version && "" !== res.data.version){
                version += '_b_'+res.data.version;
              }
            }
            sessionStorage.setItem('version', version);
            this.$alert(`${this.$t('header.a9')} ${version}`, `${this.$t('header.a7')}`,
              {confirmButtonText: `${this.$t('header.a8')}`});
          }, true, 
          error => {
            // 在这里处理请求错误，error 为捕获到的错误对象
            console.error('Custom Error Handling:', error);
            sessionStorage.setItem('version', version);
            this.$alert(`${this.$t('header.a9')} ${version}`, `${this.$t('header.a7')}`,
              {confirmButtonText: `${this.$t('header.a8')}`});
          }
        )
      } else {
        this.$alert(`${this.$t('header.a9')} ${version}`, `${this.$t('header.a7')}`,
            {confirmButtonText: `${this.$t('header.a8')}`});
      }
    },

    checkLanguage(lan) {
      this.$i18n.locale = lan;
    },
    goCP() {
      this.$router.push('/ResetPassword');
    },

    goParameters() {
      this.$router.push('/PhoneProfile');
    },

    goTools() {
      this.$router.push('/Users');
    },

    logout() {
      this.logoutAndClear({type: 1})
      this.$router.push('/')
    }
  }
}
</script>

<style>
.search .el-input__inner {
  border-radius: 10px;
  background: #9DB2F9;
  border: none;
  color: white;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hearder-nav-wrap {
  width: 100%;
  height: 70px;
  background: #5D80F5;
}

.user-info {
  position: absolute;
  right: 20px;
  line-height: 70px;
}

.hearder-nav {
  width: 70%;
  line-height: 70px;
  min-width: 1200px;
  margin: 0 auto;
  color: white;
  display: flex;
  justify-content: space-between;
}

.header-right-tools {
  display: flex;
  justify-content: space-between;
  font-size: .35rem;
}

.header-right-tools li {
  margin-right: 20px;
}

.search {
  width: 190px;
}

.shu {
  width: 1px;
  height: 35px;
  background: white;
  margin-top: 17px;
}

.change-pw {
  padding: 0 10px;
  height: 30px;
  font-size: .3rem;
  margin-top: 20px;
  vertical-align: middle;
  line-height: 30px;
  border: 1px solid white;
  border-radius: 30px;
  cursor: pointer;
  text-align: center;
}

.about {
  font-size: 16px;
  color: white;
  margin-right: 20px;
  cursor: pointer;
}
</style>
