<template>
  <div class="hello">
    <section class="login-block">
      <div class="login-logo">
        <div>{{ $t('login.a7') }}</div>
        <div>{{ $t('login.a8') }}</div>
      </div>
      <div class="login-info">
        <div class="login-info2">
          <el-select v-model="value" @change="selectChange">
            <el-option value="fr" :label="$t('login.a9')"></el-option>
            <el-option value="en" :label="$t('login.a10')"></el-option>
          </el-select>
        </div>
        <div class="login-info1">
          <div class="input-sty">
            <img src="../assets/img/pengyou.png" style="position:absolute;left:0;bottom: 2px;" alt="">
            <input v-model="name" type="text" @input="iptChange" :placeholder="$t('login.a3')"/>
          </div>
          <div class="input-sty" style="margin-top: 90px;">
            <img src="../assets/img/jiesuo.png" style="position:absolute;left:0;bottom: 2px;" alt="">
            <input v-model="pwd" type="password" :placeholder="$t('login.a4')"/>
          </div>
          <div class="btn" @click="login">{{ $t('login.a11') }}</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      name: '',
      pwd: '',
      value: this.$i18n.locale
    }
  },
  methods: {
    // choose language
    selectChange(e) {
      this.$i18n.locale = e;
    },
    iptChange(e) {
      let val = e.target.value
      let temp = ""
      for (let i = 0; i < val.length; i++) {
        if (val.charCodeAt(i) > 0 && val.charCodeAt(i) < 255) temp += val.charAt(i)
      }
      this.name = temp.trim()
    },
    login() {
      this.postRequest('index/login', {
        name: this.name,
        pwd: this.pwd
      }, (res) => {
        if (res.code == 200) {
          sessionStorage.setItem('id', res.data.id);
          sessionStorage.setItem('name', res.data.name);
          sessionStorage.setItem('pwd', this.pwd);
          sessionStorage.setItem('token', res.data.token);
          sessionStorage.setItem('user', JSON.stringify(res.data))
          // if(res.data.quanxian == 'Mainteneur') {

          // }
          if (this.pwd == '000000') {
            this.$message({
              type: 'warning',
              message: this.$t('message.a12')
            })
            this.$router.push('/ResetPassword')
          } else {
            this.$router.push('/home')
          }
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  width: 100vw;
  height: 100vh;
  background: url(../assets/login-bg.png) no-repeat;
  background-size: cover;
  background-position: center;
}

.login-block {
  width: 75vw;
  height: 75vh;
  min-width: 1000px;
  position: relative;
  top: 12vh;
  margin: auto;
}

.login-logo {
  width: 50%;
  height: 100%;
  color: #fff;
  font-size: 1.1rem;
  background-color: rgba(255, 255, 255, .6);
  display: inline-block;
  text-align: center;
  vertical-align: top;
  line-height: 130px;
  padding: 20vh 0;
  box-sizing: border-box;
}

.login-info {
  width: 50%;
  height: 100%;
  background: #FFFFFF;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
}

.login-info2 {
  float: right;
  margin: 20px;
  font-size: 20px;
  width: 100px;
}

.login-info1 {
  width: 100%;
  background: #FFFFFF;
  display: inline-block;
  vertical-align: top;
  padding: 10vh 7% 0 14%;
  box-sizing: border-box;
}

.input-sty {
  width: 80%;
  height: 50px;
  position: relative;
}

.input-sty input {
  width: 100%;
  border: none;
  height: 50px;
  min-width: 400px;
  text-indent: 50px;
  font-size: 24px;
  border: none !important;
  caret-color: #D2D2D2;
  border-bottom: 2px solid #DBDDDC !important;
}

.input-sty input:focus-visible {
  outline: none;
}

.input-sty ::-webkit-input-placeholder {
  /* WebKit browsers，webkit内核浏览器 */
  color: #D2D2D2;
}

.input-sty :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #D2D2D2;
}

.input-sty ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #D2D2D2;
}

.input-sty :-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #D2D2D2;
}

.btn {
  width: 80%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
  border-radius: 30px;
  margin-top: 90px;
  background: #5D80F5;
  color: white;
}

.login-info2 >>> .el-input__inner {
  border: none;
}
</style>
