<template>
  <div>
    <div class="content">
      <el-menu
          class="nav"
          @select="navChange"
          background-color="#fff"
          router
          :default-active="navIndex">
        <!--权限控制-->
        <el-menu-item index="Users" v-if="quanxian == 'Admin'">
          <span slot="title">{{ $t('tools.a1') }}</span>
        </el-menu-item>
        <el-menu-item index="UserProfiles">
          <span slot="title">{{ $t('tools.a2') }}</span>
        </el-menu-item>
        <el-menu-item index="PhoneIMEI">
          <span slot="title">{{ $t('tools.a3') }}</span>
        </el-menu-item>
        <el-menu-item index="ImportLogsFile">
          <!--           <i class="el-icon-setting"></i>-->
          <span slot="title">{{ $t('tools.a4') }}</span>
        </el-menu-item>
        <el-menu-item index="ReadLogsFile" v-if="quanxian == 'Admin'">
          <span slot="title">{{ $t('tools.a5') }}</span>
        </el-menu-item>
        <!--权限控制-->
        <el-menu-item index="ImportNewVersion" v-if="quanxian == 'Admin'">
          <span slot="title">{{ $t('tools.a6') }}</span>
        </el-menu-item>

      </el-menu>
      <div style="width: 2px;height: 100%;background: #E6EAF0;"></div>
      <div class="content-item">
        <el-scrollbar style="height: 100%;">
          <router-view style="padding: 45px 45px 0 45px;"></router-view>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      navIndex: 'Users',
      status: 0,

      quanxian: ''
    }
  },
  watch: {
    '$root.status': {
      handler() {
        this.status = this.$root.status
      },
      deep: true
    },
  },
  created() {

    let user = sessionStorage.getItem('user');
    if (user) this.quanxian = JSON.parse(user).quanxian;
  },
  methods: {
    navChange(e) {
      this.navIndex = e;
    }
  }
}
</script>

<style>
.content-item .el-scrollbar__wrap {
  overflow-x: hidden;
}

.nav .el-menu-item > span {
  display: inline-block;
  white-space: initial;
  line-height: 20px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  display: flex;
  height: 85vh;
}

.nav {
  width: 20%;
  min-width: 265px;
}

.content-item {
  width: 80%;
  height: 100%;
  box-sizing: border-box;

  box-sizing: border-box;
}
</style>
