<template>
  <div>
    <div style="font-size: 36px;">Users</div>
    <!-- search -->
    <div class="flex mar30">
      <div class="flex" style="width: 60%;">
        <div style="width: 89%;">
          <input type="text" v-model="name" class="search" :placeholder="$t('tools.a77')">
        </div>
        <div class="search-btn" @click="getList"><i class="el-icon-search"></i></div>
      </div>
      <div>
        <el-button size="large" type="primary" style="margin-right: 30px;" @click="centerDialogVisible = true">
          {{ $t('common.a1') }}
        </el-button>
        <el-button size="large" @click="remove">{{ $t('common.a2') }}</el-button>
      </div>
    </div>

    <el-table
        :data="tableData"
        @selection-change="checkSelect"
        class="table mar30"
        border
        height="500px"
        style="width: 100%;">
      <el-table-column
          type="selection"
          width="60">
      </el-table-column>
      <el-table-column
          type="index"
          :label="$t('param.number')"
          width="180">
      </el-table-column>
      <el-table-column
          prop="name"
          sortable
          :label="$t('param.a12')">
      </el-table-column>
      <el-table-column
          prop="quanxian"
          sortable
          :label="$t('tools.a7')">
      </el-table-column>
      <el-table-column
          prop="createtime"
          sortable
          :formatter="_formatDate"
          :label="$t('param.a9')">
      </el-table-column>
      <el-table-column
          prop="pwd"
          sortable
          :label="$t('tools.a72')">
        <template slot-scope="scope">
          <div v-if="scope.row.editPwd">
            <el-input v-model="pwd" :maxlength="20" size="small" style="border:none;"
                      @input="saveIpt" @blur="savePwd(scope.row)"></el-input>
          </div>
          <div v-else style="position: relative;">
            <div>{{ scope.row.pwd }}</div>
            <div @click="editPwd(scope.row)">
              <el-icon style="position: absolute;right: 0px;top:0;cursor: pointer;"
                       class="el-icon-edit-outline"></el-icon>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center;">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          background
          layout="prev, pager, next"
          :total="total">
      </el-pagination>
    </div>


    <el-dialog
        :title="$t('common.a1')"
        :visible.sync="centerDialogVisible"
        width="30%"
        center>
      <el-form label-position="right" label-width="80px" :rules="rules" :model="form">
        <el-form-item :label="$t('param.a12')" prop="name" label-width="120px">
          <el-input v-model.trim="form.name" :maxlength="20" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item :label="$t('tools.a7')" prop="quanxian" label-width="120px">
          <el-select v-model="form.quanxian" style="width: 300px;" :placeholder="$t('common.a9')">
            <el-option
                v-for="(item, idx) in quanxianList"
                :key="idx"
                :label="item"
                :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">{{ $t('common.a7') }}</el-button>
        <el-button type="primary" @click="save">{{ $t('common.a4') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formatDate } from '@/utils/http';
export default {
  data() {
    return {
      pid: '',
      pageNum: 1,
      pageSize: 10,
      total: 0,
      search: '',
      name: '',
      quanxian: '',
      quanxianList: [],
      visible: false,//修改密码的窗口
      pwd: '',//编辑修改的密码

      deleteList: [],
      tableData: [],

      centerDialogVisible: false,
      form: {
        name: '',
        quanxian: '',
      },
      rules: {
        name: [
          {required: true, message: ' ', trigger: 'blur'},
        ],
        quanxian: [
          {required: true, message: ' ', trigger: 'change'}
        ]
      }

    }
  },
  created() {
    let data = sessionStorage.getItem('file')
    if (data) {
      this.pid = JSON.parse(data).id;
    }
    // this.edit = sessionStorage.getItem('edit');
    this.getList();
    this.getquanxianList();
  },
  methods: {
    getquanxianList() {
      this.postRequest('index/quanxian_list', {
        uid: sessionStorage.getItem('id')
      }, res => {
        this.quanxianList = res.data;
      }, true)
    },
    getList() {
      let params = {
        page: this.pageNum,
        page_size: this.pageSize,
        uid: sessionStorage.getItem('id'),
      }
      if (this.name) {
        params.name = this.name;
      }
      if (this.quanxian) {
        params.quanxian = this.quanxian;
      }
      this.postRequest('index/get_users_list', params, res => {
        this.tableData = res.data.info.map(v => {
          v.editPwd = false;
          return v;
        });
        this.total = res.data.count;
      }, true)
    },
    save() {
      if (!(
          this.form.name &&
          this.form.quanxian
      )) {
        return false;
      }
      this.postRequest('index/add_user', {
        uid: sessionStorage.getItem('id'),
        ...this.form
      }, () => {
        this.getList();
        this.form = {
          name: '',
          quanxian: ''
        }
        this.centerDialogVisible = false
      })
    },
    checkSelect(e) {
      this.deleteList = e.map(v => v.id)
    },
    remove() {
      if (this.deleteList.length == 0) return;
      this.$confirm(this.$t('message.a9'), this.$t('common.a2'), {
        confirmButtonText: this.$t('cp.a4'),
        cancelButtonText: this.$t('cp.a5'),
        type: 'warning'
      }).then(() => {
        this.postRequest('index/del_users', {ids: this.deleteList.join(',')}, () => {
          this.$message({
            type: 'success',
            message: this.$t('message.a6')
          });
          this.getList();
        })
      })
    },
    // 在 Input 值改变时触发
    saveIpt(e) {
      let temp = ""
      for (let i = 0; i < e.length; i++) {
        if (e.charCodeAt(i) > 0 && e.charCodeAt(i) < 255) temp += e.charAt(i)
      }
      this.pwd = temp
    },
    savePwd(item) {
      let pwd = this.pwd.trim()
      if (this.checkPWD() === false) {
        return this.$message({
          type: 'warning',
          message: this.$t('message.a20')
        })
      } else {
        this.postRequest('index/edit_pwd', {
          uid: sessionStorage.getItem('id'),
          id: item.id,
          pwd: pwd,
        }, res => {
          item.pwd = pwd;
          this.$message({
            type: 'success',
            message: this.$t('message.a5')
          })
        }, true)
      }

      this.pwd = ''
      item.editPwd = false;
    },
    editPwd(item) {
      item.editPwd = true;
      // this.pwd = item.pwd;
      this.visible = true;
    },
    checkPWD() {
      return /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*[`~!@#$%^&*()_\-+=<>.?:"{}].*).{6,20}$/.test(this.pwd.trim());
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    _formatDate(row, col, value, index) {
      return formatDate(value);
    },
  }
}
</script>

<style scoped>
.search {
  width: 100%;
  height: 46px;
  border: 3px solid #5D80F5;
  border-radius: 1px;
  font-size: 18px;
  text-indent: 20px;
  box-sizing: border-box;

}

.search:focus-visible {
  outline: none;
}

.search-btn {
  width: 11%;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
  color: #C3D0FB;
  background: #5D80F5;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.next-btn {
  margin-bottom: 30px;
}

.mar30 {
  margin-top: 30px;
}
</style>
