import axios from "axios";
import {Message} from 'element-ui'


let base = '/api/';

let getRequest = function (url, data, func, progress) {
    url = url.replace('http://fatie.tolinksoft.com', '')
    axios({
        url: url,
        method: 'get',
        params: data,
        responseType: 'blob',
        onDownloadProgress: function (progressEvent) {//axios封装的原生获取下载进度的事件，该回调参数progressEvent中包含下载文件的总进度以及当前进度
            if (progress) {
                if (progressEvent.lengthComputable) {
                    progress(progressEvent)
                    //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
                    //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
                }
            }
        }
    }).then(res => {
        if (res.status == 200) {
            func(res.data)
        } else if (res.data) {
            Message.error({
                type: 'error',
                message: res.data.msg
            })
        }
    })
};
let request = function (url, data, func, isJson, errorHandler) {
    //判断半小时未操作重新登录
    const lastTime = sessionStorage.getItem('control')
    if (url != 'index/login' && url != 'Index/log_out' && lastTime != 'null' && (new Date().getTime() - Number(lastTime) > 1800000)) {
        vue1.$message({
            type: 'warning',
            message: vue1.$t('message.a19')
        })
        vue1.logoutAndClear({type: 0})
        vue1.$router.push('/')
        return;
    }

    axios({
        url: base + url,
        ...requestConfig('post', {uid: sessionStorage.getItem('id'), ...data}, isJson)
    }).then(res => {
        sessionStorage.setItem('control', new Date().getTime())

        if (sessionStorage.getItem('pwd') !== '000000') {
            if (res.status == 200) {
                if (res.data.code == 200) {
                    func(res.data)
                } else {
                    if (res.data.msg) {
                        Message.error({
                            type: 'error',
                            message: res.data.msg
                        })
                    }
                }
            } else if (res.status == 206) { //token失效
                if (vue1.$route.path == '/' || vue1.$route.path == '') return;
                Message.error({
                    type: 'error',
                    message: vue1.$t('message.a18')
                })
                vue1.logoutAndClear({type: 0})
                vue1.$router.push('/')
            } else if (res.data) {
                Message.error({
                    type: 'error',
                    message: res.data.msg
                })
            }
        } else {
            Message.error({
                type: 'error',
                message: vue1.$t('message.a18')
            })
            vue1.$router.push('/ResetPassword')
        }
    }).catch(error => {
        // 将错误交由外部处理
        if (errorHandler) {
            errorHandler(error);
        } else {
            //console.error('Request Error:', error);
            // 在这里可以添加其他默认处理逻辑
        }
    })
};
let formatDate = function(timestamp) {
    if (!timestamp) return ''; // To prevent invalid values
    const userDate = new Date(timestamp * 1000);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    return userDate.toLocaleString('en-GB', options); // Formats the date in "d-m-Y H:i:s" format
  }
  
export {request, getRequest, requestFile, formatDate};

let requestFile = function (url, data, func, progress) {
    axios({
        method: 'post',
        url: base + url,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data;",
            token: sessionStorage.getItem('token'),
            uid: sessionStorage.getItem('id'),
        },
        //获取上传进度
        onUploadProgress: function (progressEvent) {
            if (progress) progress(progressEvent)
        }

    }).then(res => {
        if (res.status == 200) {
            if (res.data.code == 200) {
                func(res.data)
            } else {
                if (res.data.msg) {
                    Message.error({
                        type: 'error',
                        message: res.data.msg
                    })
                }
            }
        } else if (res.data) {
            Message.error({
                type: 'error',
                message: res.data.msg
            })
        }
    })
}

function requestConfig(method, params, isJson = false) {
    let requestConfig = {
        method: method,
        headers: {
            "Content-Type": isJson ?
                "application/json" : "application/x-www-form-urlencoded",
            token: sessionStorage.getItem('token'),
            uid: sessionStorage.getItem('id'),
        },
    };
    if (method === "get" || method === "delete") {
        requestConfig.params = params;
        requestConfig.paramsSerializer = params => {
            let ret = ''
            for (let it in params) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(params[it]) + '&'
            }
            return ret
        }
    } else {
        requestConfig.data = isJson ?
            params :
            function () {
                let ret = ''
                for (let it in params) {
                    ret += encodeURIComponent(it) + '=' + encodeURIComponent(params[it]) + '&'
                }
                return ret
            }();
    }
    return requestConfig;
}